import { Card } from '@nextui-org/card'
import React from 'react'

export default function PageCard({
  children,
  className = '',
  id,
  isPadded = true,
  isVisible = true
}: {
  children: React.ReactNode
  className?: string
  id?: string
  isPadded?: boolean
  isVisible?: boolean
}) {
  return (
    <Card
      id={id}
      shadow={(isVisible?'sm':'none')}
      className={'' +((isPadded && isVisible)&&'p-6')+' ' + className}
      radius={(isVisible?'lg':'none')}
      fullWidth
    >
      {children}
    </Card>
  )
}
